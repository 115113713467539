export const chick = [
    {
      name: "Chicken 1",
      description: "Freshly processed chicken",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "art2.jpg"
    },
    {
      name: "Chicken 2",
      description: "Freshly processed chicken",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "art1.jpg"
    },
    {
      name: "Chicken 3",
      description: "Freshly processed chicken",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "art2.jpg"
    },
    {
      name: "Chicken 4",
      description: "Freshly processed chicken",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "art1.jpg"
    },
    {
      name: "Chicken 5",
      description: "Freshly processed chicken",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "art2.jpg"
    },
    {
      name: "Chicken 6",
      description: "Freshly processed chicken",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "art1.jpg"
    }
  ]


  export const eggs = [
    {
      name: "Poultry Eggs",
      description: "Fresh Poultry Eggs",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "egg2.jpg"
    },
    {
      name: "Poultry Eggs",
      description: "Fresh Poultry Eggs",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "egg1.jpg"
    },
    {
      name: "Poultry Eggs",
      description: "Fresh Poultry Eggs",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "egg2.jpg"
    },
    {
      name: "Poultry Eggs",
      description: "Fresh Poultry Eggs",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "egg1.jpg"
    },
    {
      name: "Poultry Eggs",
      description: "Fresh Poultry Eggs",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "egg2.jpg"
    },
    {
      name: "Poultry Eggs",
      description: "Fresh Poultry Eggs",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "egg1.jpg"
    }
  ]


  export const soya = [
    {
      name: "Soya",
      description: "Freshly processed Soya",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "soya2.jpg"
    },
    {
      name: "Soya",
      description: "Freshly processed Soya",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "soya1.jpg"
    },
    {
      name: "Soya",
      description: "Freshly processed Soya",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "soya2.jpg"
    },
    {
      name: "Soya",
      description: "Freshly processed Soya",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "soya1.jpg"
    },
    {
      name: "Soya",
      description: "Freshly processed Soya",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "soya2.jpg"
    },
    {
      name: "Soya",
      description: "Freshly processed Soya",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "soya1.jpg"
    }
  ]


  export const maize = [
    {
      name: "Maize",
      description: "Freshly processed Maize",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "maize2.jpg"
    },
    {
      name: "Maize",
      description: "Freshly processed Maize",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "maize1.jpg"
    },
    {
      name: "Maize",
      description: "Freshly processed Maize",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "maize2.jpg"
    },
    {
      name: "Maize",
      description: "Freshly processed Maize",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "maize1.jpg"
    },
    {
      name: "Maize",
      description: "Freshly processed Maize",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "maize2.jpg"
    },
    {
      name: "Maize",
      description: "Freshly processed Maize",
      price: 34,
      promoted: false,
      available: true,
      minQty: 50,
      weight: 2.5,
      units: "kg",
      image: "maize1.jpg"
    }
  ]