<template>
  <div :class="isMobile? 'my-4 mx-3' : 'mt-4 mb-15 d-block'">
    <v-row
    align="center"
    justify="center"
    >
      <v-col>
        <v-carousel
          cycle
          no-resize
          hide-delimiter-background
          hide-delimiters
          :show-arrows="false"
          :height="isMobile? '100':'250'"
        >
        <v-carousel-item
          replace
          src="../../../assets/imgs/chicken-banner.jpg"
          lazy-src=""
          class="animate__animated animate__fadeIn animate__slow	2s"
        >
        <div class="text-container ml-n10 ml-md-0">
          <h1 class="text text-h5 text-md-h2 animate__animated animate__fadeInDown">Enjoy the Best</h1>
          <p class="subtext text-subtitle-1 mt-n2 mt-md-0 text-md-h4 animate__animated animate__fadeInUp animate__delay-1s " style="margin-top: -20px;">Freshly Processed Chicken</p>
        </div>
        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
        </v-carousel-item>
        <v-carousel-item
          replace
          src="../../../assets/imgs/eggs-banner.jpg"
          lazy-src=""
          class="animate__animated animate__fadeIn animate__slow	2s"
        >
        <div class="text-container1 mr-n13 ml-md-0">
          <h1 class="text text-h5 text-md-h2 animate__animated animate__fadeInDown">Enjoy the Best</h1>
          <p class="subtext text-subtitle-1 mt-n2 mt-md-0 text-md-h4 animate__animated animate__fadeInUp animate__delay-1s " style="margin-top: -20px;">Wholesome Poultry Eggs</p>
        </div>
        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
        </v-carousel-item>
        <v-carousel-item
          replace
          src="../../../assets/imgs/maize-banner.jpg"
          lazy-src=""
          class="animate__animated animate__fadeIn animate__slow	2s"
        >
        <div class="text-container ml-n10 ml-md-0">
          <h1 class="text text-h5 text-md-h2 animate__animated animate__fadeInDown">Buy the Best</h1>
          <p class="subtext text-subtitle-1 mt-n2 mt-md-0 text-md-h4 animate__animated animate__fadeInUp animate__delay-1s " style="margin-top: -20px;">High Quality Maize</p>
        </div>
        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
        </v-carousel-item>
        <v-carousel-item
          replace
          src="../../../assets/imgs/soya-banner.jpg"
          lazy-src=""
          class="animate__animated animate__fadeIn animate__slow	2s"
        >
        <div class="text-container1 mr-n10 ml-md-0">
          <h1 class="text text-h5 text-md-h2 animate__animated animate__fadeInDown">Buy the Best</h1>
          <p class="subtext text-subtitle-1 mt-n2 mt-md-0 text-md-h4 animate__animated animate__fadeInUp animate__delay-1s " style="margin-top: -20px;">High Quality Soya Beans</p>
        </div>
        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
        </v-carousel-item>
        <v-carousel-item
          replace
          src="../../../assets/imgs/shea-banner.jpg"
          lazy-src=""
          class="animate__animated animate__fadeIn animate__slow	2s"
        >
        <div class="text-container1 mr-n10 ml-md-0">
          <h1 class="text text-h5 text-md-h2 animate__animated animate__fadeInDown">Buy the Best</h1>
          <p class="subtext text-subtitle-1 mt-n2 mt-md-0 text-md-h4 animate__animated animate__fadeInUp animate__delay-1s " style="margin-top: -20px;">High Quality Shea Nuts</p>
        </div>
        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
        </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-container fluid :style="isMobile ? {width: '85%'} :{width: '71%'}">
      <v-row justify="end">
        <v-col cols="12" md="6">
          <mini-chip-filter @search="search" ></mini-chip-filter>
        </v-col>
      </v-row>
      <v-row :justify="isMobile ? 'center' : 'start'" class="d-flex">
      <!-- <v-chip
          @click="changeCategoryAndSearch('')"
          :outlined="'' == selectedCategory"
          class="ma-2"
          color="primary"
      >
          <v-avatar class="mr-2">
            <v-icon>mdi-filter-variant</v-icon>
          </v-avatar> -->
          <!-- {{chip.name}} -->
          <!-- All
      </v-chip>
      <v-chip
          v-for="(category, index) in categories" :key="index"
          @click="changeCategoryAndSearch(category.slug)"
          :outlined="category.slug == selectedCategory"
          class="ma-2"
          color="primary"
      >
          <v-avatar class="mr-2">
            <v-icon>mdi-filter-variant</v-icon>
          </v-avatar> -->
          <!-- {{chip.name}} -->
          <!-- {{category.name}}
      </v-chip>
        -->
        <v-col align="center" sm="6" md="4" cols="12" :key="product.id" v-for="( product, index ) in productList">
          <v-card
              @click="goToProduct(product); prodDialog = true"
              :color="(index + 1) %2 === 0 ? '#f1f6f5' : '#ffffff'"
              class="elevation-0"
          >
            <div style="position: absolute; top: 10px; right: 10px" class=""><img src="@/assets/imgs/ghana_tag.jpg" style="width: 180px;"/></div>
            <v-img
                :src="`${imgHost}imgs/products/${product.product_type.slug}/${product.img_url}?w=400`"
                :lazy-src="`${imgHost}imgs/products/${product.product_type.slug}/${product.img_url}?w=10`"
                style="height: 100%; width: 100%"
            >
            <v-card-text class="text-start pl-0 pl-md-2">
              <v-card-title class="font-weight-bold text-h6 text-md-h5 pt-2 pt-md-5 mt-2">{{ product.name }}</v-card-title>
              <v-card-subtitle class="font-italic font-weight-medium text-caption text-md-subtitle-1"> {{isMobile ? 'Min Qty:' : 'Min Qty:' }} {{ product.minimum }}{{ minimumSoyaStatus(product) || minimumMaizeStatus(product) || product.name === 'Shea Nuts' ? 'MT' : ''}}</v-card-subtitle>
              <div class="d-block py-0">
                <v-card-title :style="isMobile ? {fontSize: '15px'}: ''" class="font-weight-bold pr-0 pt-0 pb-0">GHS {{ product.price }}</v-card-title>
                <p v-if="product.name.includes('Full Chicken')" class="text-caption font-weight-thin grey--text align-self-end font-weight-medium pl-4 mt-n3"></p>
                <p v-else class="text-caption font-weight-thin grey--text align-self-end font-weight-medium pl-4 mt-n3">per {{Number(product.weight) === 1 ? '' : product.weight}} {{product.units}}</p>
              </div>
            </v-card-text>

            <div class="pb-0 pb-md-2 pt-0" style="font-weight: 700; position: absolute; bottom: 20px; left: 15px; margin-right: 10px;">
              <v-btn
                v-if="isMobile"
                x-small
                color="primary"
                class="ml-2 px-4"
                outlined
              >
                Buy
              </v-btn>
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                v-else
              >
                Buy
              </v-btn>
            </div>
            </v-img>
        </v-card>
      </v-col>
    </v-row>
      <v-row align="center" justify="center">
        <v-progress-circular v-if="loadingTrending" indeterminate size="72" color="primary"></v-progress-circular>
        <!-- <category-card v-else :products="productList"></category-card> -->
      </v-row>
      <v-dialog 
      v-model="prodDialog"
      transition="dialog-top-transition"
      activator="parent"
      width="auto"
      :fullscreen="!!isSmallScreen"
      >
        <new-product-modal v-on:product_modal="prodDialog = false" :prodSet="selectedProduct" :dialog="prodDialog"></new-product-modal>
        <!-- <new-product-card :prod_details="selectedProduct"></new-product-card> -->
      </v-dialog>
    </v-container>  
  </div>
</template>
<script>
  import Crud from '../../../gql/PrismaCrud.gql'
  const categoryGQL = new Crud('category')
  const productGQL = new Crud('product')
  const productTypeGQL = new Crud('productType')
  import CategoryCard from "../../../components/Website/Category/CategoryCard"
  // import NewProductCard from '../../../components/Website/Product/NewProductCard.vue'
  import NewProductModal from '../../../components/Website/Product/NewProductModal.vue'
  import MiniChipFilter from "../../../components/Website/Category/MiniChipFilter"
  import { chick, eggs, soya, maize } from "../../../utils/common"

  import mixpanel from 'mixpanel-browser';
  mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true})

  export default {

    name:'Categories',
    components:{
    CategoryCard,
    MiniChipFilter,
    NewProductModal
    },

    data() {
      return {
        searchPhrase: '',
        selectedCategory: '',
        selectedProduct: '',
        productList:[],
        products:[],
        categories: [],
        cateID:"",
        skip: 0,
        itemsReturned: 20,
        hasNext: false,
        prodDialog:false,
        loadingTrending: false,
      }
    },
    methods:{
      minimumSoyaStatus(product) {
        return product.name === 'Cleaned Soya' || product.name === 'Uncleaned Soya'
      },
      minimumMaizeStatus(product) {
        return product.name === 'Yellow Maize' || product.name === 'White Maize'
      },
      nextPage(){
        this.skip++
        this.getProducts()
      },

      backPage(){
        this.skip--
        this.getProducts()
      },

      search(phrase){
        this.searchPhrase = phrase
        this.getProducts()
      },

      changeCategoryAndSearch(slug){
        this.selectedCategory = slug
        this.getProducts()
      },
      goToProduct(item){
        mixpanel.track("Product Clicks", {
          product: `clicked ${item.name}`,
          product_price: `${item.price}`
        });
        this.selectedProduct = item;
      },
      // getCategories(){
      //
      //   categoryGQL.find(`{
      //     id
      //     name
      //     slug
      //   }`,
      //   {
      //     where: {
      //       product_type: {
      //         slug: this.$route.params.slug
      //       }
      //     }
      //   }).then(res=>{
      //     console.log(res)
      //     this.categories = res.data
      //   }).catch(err=>{
      //     console.error(err)
      //   })
      // },
      getProducts(){
        this.loadingTrending = true
        productGQL.find(`{
          id
          name
          img_url
          lazy_url
          status
          weight
          units
          minimum
          usage
          price
          product_type{
              id
              name
              slug
          }
          category{
              id
              name
              slug
          }
          company{
            id
            name
            location
            phone
          }
          createdAt
        }`, {
          orderBy: "createdAt_ASC",
          first: this.itemsReturned,
          skip: this.skip * this.itemsReturned,
          where: {
            OR: [
              { category: { name_contains: this.searchPhrase}},
              {name_contains: this.searchPhrase}
            ],
            // category: {
            //   id : this.selectedCategory
            // },
            product_type: {
              id: this.$route.params.slug
            }
          }
        })
          .then(res=>{
            this.productList = []
            this.productList = res.data.filter( item => item.status === 'active' )
            this.hasNext = res.hasNext
          }).catch(err=>{
            this.$error('Error fetching trending products', err.message)
          }).finally(_=>{
            this.loadingTrending = false
          })
      },
    },
    created(){
      // this.getCategories()
      this.getProducts()
      this.cateID = this.$route.params.slug
      if (this.cateID === "cliacqued004d0655wl2i7ucd") {
        this.products = chick;
      } else if(this.cateID === "cliacpjd30048065581m4fqcb"){
        this.products = eggs;
      } else if(this.cateID === "cliacp9bx00430655wp394gy8"){
        this.products = soya;
      } else {
        this.products = maize;
      }
    },

    mounted() {
      console.log('products here',this.productList)
    }
  }
</script>

<style lang="scss" scoped>
  .filter{
    background: #fff;
    border-radius: 32px;
  }

  .text-container {
    position: absolute;
    top: 25%;
    left: 20%;
  }

  .text-container1 {
    position: absolute;
    top: 25%;
    right: 20%;
    text-align: end;
  }

  .text {
    font-weight: bolder;
    font-size: 4rem;
    color: rgb(32, 32, 32);;
    font-family: 'Clash Display' !important;
  }

  .subtext {
    font-weight: 500;
    font-size: 2rem;
    color: rgb(32, 32, 32);
    font-family: 'Clash Display' !important;
  }

  .porto{
    background:rgba(255,255,255,0.6)
  }
  .dud{
    color: #fff;
    font-size: 90px;
    font-weight:bold;
    line-height: 1em;
    margin: 0;
    text-align: left;
    transform: translateY(-50%);
    width: 100%;
  }
  #akoid{
      border:1px solid #e3e3e3;
      width:500px;
      padding:20px;
      margin:20px;
      border-radius:15px;
      background:#ffffffaa;
  }

</style>
